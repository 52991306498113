import { strings as stringGlobal } from '../../utils/globalLocalization'
import { strings as stringLocal } from '../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Pagination, Spin, Tooltip } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { GlobalStateInterface, useGlobalState } from "../../utils/globalStateProvider"
import { useEffect } from 'react'
import { getAuth0Token, getLangByLocalStorage, scrollTop } from '../../utils/helpers'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../utils/globalStateProviderApp'
import TableMain, { TableProps } from '../../components/molecules/TableMain'
import { mergeDeepRight } from 'ramda';
import { Case, CaseAsset, PagedCases } from '../interfaces/Case'
import { fetchCases } from '../utils/helpersFetch'
import SearchHistory from '../../components/SearchHistory'
import { getTransformedIncidentStatus } from '../utils/helpers'
const colorsTheme = require('../../../src/utils/aufzugheldenTheme')

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
const lang = getLangByLocalStorage()
I18n.setLanguage(lang)

moment.locale(lang ? lang : 'de');

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface PrevRef {
  searchTerm: string | undefined
  sortBy: string | undefined
}

const TableIncidents = (props: {}) => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();

  const query = useQuery();

  const [pageUrlParam, setPageUrlParam] = React.useState<number | undefined>()
  const [searchValueUrlParam, setSearchValueUrlParam] = React.useState<string>()
  const [sortByUrlParam, setSortByUrlParam] = React.useState<string | undefined>()
  const [totalNumberOfIncidents, setTotalNumberOfIncidents] = React.useState<number>(0)
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })
  const prevRef = useRef<PrevRef>({ searchTerm: undefined, sortBy: undefined });
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [tableProps, setTableProps] = useState<TableProps | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [state.language]);

  const sortChange = (sorter: { columnKey: string, order: string }) => {

    if (sorter.order === '') {
      setSortedInfo({ columnKey: '', order: '' })
    }
    else {
      setSortedInfo(sorter)
    }

    let sortBy = ''
    if (sorter.columnKey && sorter.order) {
      sortBy = sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
    }
    setPageUrlParam(1)
    setSortByUrlParam(sortBy)
  };

  useEffect(() => {
    if (!searchValueUrlParam && !pageUrlParam && !sortByUrlParam) {

      if (stateApp.search) {
        // case hits if components gets loaded > like coming from installation detail page
        // 2 cases can happen / Back button pressd or logo in header 
        if (query.get('back') === 'true') {
          const pageParam = query.get("page") ? query.get("page") : '1'
          if (pageParam !== null) {
            setPageUrlParam(parseInt(pageParam))
          }
          else {
            setPageUrlParam(1)
          }

          const searchParam = query.get("search")
          if (searchParam !== undefined && searchParam !== null) {
            setSearchValueUrlParam(searchParam)
          }
          else {
            setSearchValueUrlParam('')
          }

          const sortByParam = query.get("sortBy")
          if (sortByParam !== undefined && sortByParam !== null) {
            const arrSplit = sortByParam.split(':')
            if (arrSplit.length === 2) {
              setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
              setSortByUrlParam(sortByParam)
            }
          }
          else {
            setSortByUrlParam('')
          }
          return
        }

        // logo clicked case
        setPageUrlParam(1)
        setSearchValueUrlParam('')
        setSortByUrlParam('')

      }
      else {
        // case hits if url gets opened directly by browser
        // init params from saved state
        const pageParam = query.get("page") ? query.get("page") : '1'
        if (pageParam !== null) {
          setPageUrlParam(parseInt(pageParam))
        }
        else {
          setPageUrlParam(1)
        }

        const searchParam = query.get("search")
        if (searchParam !== undefined && searchParam !== null) {
          setSearchValueUrlParam(searchParam)
        }
        else {
          setSearchValueUrlParam('')
        }

        const sortByParam = query.get("sortBy")
        if (sortByParam !== undefined && sortByParam !== null) {
          const arrSplit = sortByParam.split(':')
          if (arrSplit.length === 2) {
            setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
            setSortByUrlParam(sortByParam)
          }
        }
        else {
          setSortByUrlParam('')
        }
      }
      return
    }

    const urlParams = '?'
      + 'page=' + (pageUrlParam ? pageUrlParam : 1)
      + '&search=' + (searchValueUrlParam ? encodeURIComponent(searchValueUrlParam) : '')
      + '&sortBy=' + (sortByUrlParam ? sortByUrlParam : '')

    const setBookableUrlTo = async () => {
      let goTo = '/org_' + state.organizationName + '/welcome' + urlParams
      if (window.location.href !== goTo) {
        navigate(goTo)
      }
    }
    if (state.organizationName) {
      setBookableUrlTo()
    }

    // storing url for having it for the backButton at the installation detail list
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      search: {
        page: (pageUrlParam ? pageUrlParam : 1),
        searchterm: (searchValueUrlParam ? searchValueUrlParam : ''),
        sortBy: (sortByUrlParam ? sortByUrlParam : ''),
        parametersUpdatedByUrl: true
      }
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))

  }, [pageUrlParam, searchValueUrlParam, sortByUrlParam, state.organizationName]);

  useEffect(() => {
    BuildItemList()
    setTotalNumberOfIncidents(stateApp.casesCount ? stateApp.casesCount : 0)

  }, [state.language, stateApp.cases,stateApp.casesCount]);

  useEffect(() => {
    if (stateApp.search?.parametersUpdatedByUrl) {
      loadIncidents();
    }

    prevRef.current.searchTerm = stateApp.search?.searchterm;
    prevRef.current.sortBy = stateApp.search?.sortBy;
  }, [stateApp.search?.page, stateApp.search?.searchterm, stateApp.search?.sortBy]);

  async function loadIncidents() {
    try {
      setIsLoading(true);
      const accessToken = await getAuth0Token(getAccessTokenSilently);
      let pagedIncidents = await fetchCases<PagedCases>(stateApp.search?.page, 10, stateApp.search?.searchterm, stateApp.search?.sortBy, '', accessToken)

      let dataGlobal: Partial<GlobalStateInterfaceApp> = {
         cases: pagedIncidents.incidents,
         casesCount: pagedIncidents.totalCount,
       }

      setStateApp((prev: any) => ({ ...prev, ...dataGlobal }));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching incidents:', error.message);
      // Handle the error here if needed
    } finally {
      setIsLoading(false);
    }
  }

  const getSortOrder = (columnKey: string): string => {
    if (sortedInfo.columnKey === columnKey) {
      if (sortedInfo.order === 'asc') {
        return 'desc';
      }
      else if (sortedInfo.order === 'desc') {
        return '';
      }
      else {
        return 'asc';
      }
    }
    else {
      return 'asc';
    }
  }

  const BuildItemList = () => {
    let tableP: TableProps = { th: [], tr: [], noDataLabel: I18n.get('NoIncidentsFound'), height: 'unset', thead: 'sticky-documents-detail' }

    tableP.th = [

      // mobile
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'status', order: getSortOrder('status') })} >{I18n.get('Active')}{getSortVisualisation('status')}</span>
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'updatedAt', order: getSortOrder('updatedAt') })} ><span className=" mr-3">/</span>{I18n.get('DateUpdated')}{getSortVisualisation('updatedAt')}</span>
        </span>,
        class: 'flex l:hidden w-5/12 items-center pb-0',
        ellipsis: false,
      },
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'recordType', order: getSortOrder('recordType') })} >{I18n.get('CaseType')}{getSortVisualisation('recordType')}</span>
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'caseNumber', order: getSortOrder('caseNumber') })} ><span className=" mr-3">/</span>{I18n.get('CaseNumber')}{getSortVisualisation('caseNumber')}</span>
        </span>,
        class: 'flex l:hidden w-7/12 items-center pb-0',
        ellipsis: false,
      },

      // desktop
      {
        text: <>{I18n.get('Active')}{getSortVisualisation('status')}</>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: false,
        onCellClick: () => sortChange({ columnKey: 'status', order: getSortOrder('status') })
      },
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'recordType', order: getSortOrder('recordType') })} >{I18n.get('CaseType')}{getSortVisualisation('recordType')}</span>
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'caseNumber', order: getSortOrder('caseNumber') })} ><span className=" mr-3">/</span>{I18n.get('CaseNumber')}{getSortVisualisation('caseNumber')}</span>
        </span>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: true,
      },
      {
        text: <>{I18n.get('DateUpdated')}{getSortVisualisation('updatedAt')}</>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: false,
        onCellClick: () => sortChange({ columnKey: 'updatedAt', order: getSortOrder('updatedAt') })
      },
      {
        text: <>{I18n.get('CaseSubject')}{getSortVisualisation('subject')}</>,
        class: 'hidden l:flex w-3/12 ',
        twoLinesEllipses: true,
        onCellClick: () => sortChange({ columnKey: 'subject', order: getSortOrder('subject') })
      },
      {
        text: <>{I18n.get('AffectedAssets')}</>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: false,
      },

      // desktop & mobile
      { text: <></>, class: 'hidden l:w-1/12 l:flex ' }
    ]

    if (stateApp.cases) {
      tableP.tr = stateApp.cases.map((itemIncidents, index) => {
        let dataRow: TableProps["tr"][0] = {
          cells:
            [
              // mobile
              { text: <>{I18n.get('IncidentStatus_' + getTransformedIncidentStatus(itemIncidents.status))}<br/>{(itemIncidents.updatedAt?moment(itemIncidents.updatedAt).format('DD.MM.YYYY'):'' )}</>, class: 'flex l:hidden text-left items-center w-5/12 hover:cursor-pointer ' },
              { text: <>{I18n.get(`IncidentType_${itemIncidents.recordType}`)}<br />{parseInt(itemIncidents.caseNumber).toString()}</>, class: 'flex l:hidden w-5/12 hover:cursor-pointer' },

              // desktop
              { text: <>{I18n.get('IncidentStatus_' + getTransformedIncidentStatus(itemIncidents.status) )}</>, class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer ' },
              { text: <>{I18n.get(`IncidentType_${itemIncidents.recordType}`)}<br />{parseInt(itemIncidents.caseNumber).toString()}</>, class: 'hidden l:flex w-2/12 hover:cursor-pointer' },
              { text: <>{ (itemIncidents.updatedAt?moment(itemIncidents.updatedAt).format('DD.MM.YYYY'):'' ) }</>, textPlain: (itemIncidents.updatedAt?moment(itemIncidents.updatedAt).format('DD.MM.YYYY'):'' ), class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer' },
              { text: <>{itemIncidents.subject}</>, textPlain: itemIncidents.subject, class: 'hidden l:flex text-left items-center w-3/12 hover:cursor-pointer' },
              { text: <>{getAffectedAssets(itemIncidents.assets)}</>, class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer whitespace-nowrap' },
              { text: <><svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>, class: ' w-2/12 l:w-1/12 justify-center hover:cursor-pointer' }
            ],
          trOnClick: (e) => { onRowClicked(itemIncidents) }
        }

        return dataRow
      })
    }

    setTableProps(tableP);
  }

  const onRowClicked = (incidents: Case) => {
    scrollTop()
    navigate('/org_' + state.organizationName + '/incidents/' + incidents._id)
  }

  const getAffectedAssets = (caseAssets: CaseAsset[]) => {
    if(caseAssets.length === 0){
      return '-';
    }

    const firstAsset = caseAssets[0];
    const firstLabel = firstAsset.label || firstAsset.assetId;
    const remainingAssetCount = caseAssets.length - 1;
    return remainingAssetCount === 0 ? 
      `${firstLabel}` : <Tooltip  placement="bottom" color={colorsTheme.blueDark}  title={<div className=" text-white">{caseAssets.map((item,index)=>{return <div key={index}>{item.label}</div>})}</div>} >{firstLabel} <span className='text-greyLight'>+{remainingAssetCount}</span></Tooltip>
;
  }

  const getSortVisualisation = (columnKey: string) => {

    if (columnKey === sortedInfo.columnKey) {
      if (sortedInfo.order === 'asc') {
        return <CaretUpOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
      else {
        return <CaretDownOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
    }
    return <CaretUpOutlined className="ml-4" style={{ 'color': 'grey' }} />
  }

  const handleSearchChange = (searchTerm: string) => {
    if (searchValueUrlParam !== searchTerm) {
      setPageUrlParam(1)
    }
    setSearchValueUrlParam(searchTerm)
  }

  return (
    <div className="idTableContracts flex flex-col mb-24">
      <div className=" mt-12  m:mt-24 flex justify-between flex-col m:flex-row">
        <h1>{I18n.get('overview')}</h1>
      </div>
      <div className=" w-full  m:w-96 pt-10 pb-10">
        {<SearchHistory setSearchValue={handleSearchChange} />}
      </div>
      <Spin spinning={isLoading}>
        <TableMain tableContent={tableProps} />
        <div className="h-24 bg-blueBaby flex justify-center items-center">
          <Pagination current={stateApp.search?.page ? stateApp.search?.page : 1} pageSize={10} showSizeChanger={false} total={totalNumberOfIncidents} onChange={(page, pageSize) => {
            setPageUrlParam(page);
            window.scrollTo({
              top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
              behavior: 'smooth',
            });
          }} />
        </div>
      </Spin>
    </div>
  );
};

export default TableIncidents;
