import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import { DateRange } from '../../interfaces/DateRange';
import { DeviceInfo, DeviceInfoResponse, DeviceShadow } from '../../interfaces/DeviceInfo';
import { fetchAllDeviceData, loadPastEvents, fetchInstallation, fetchDevicesStatus, fetchRunsOverTime, setOrReportError, fetchDoorCyclesOverTime } from '../../utils/helpersFetch';
import { Installation } from '../../interfaces/Installation';
import './../components/detail/DoorAnimation.css';
import { Select, Spin } from 'antd';
import moment from 'moment';
import LevelVisualization from '../components/detail/LevelVisualization'
import { FeedMessageDictionary } from '../../interfaces/Lifefeed';
import io from 'socket.io-client';
import React from 'react';
import { strings as stringLocal} from './InstallationDetailLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { app_url_contracts, app_url_documents, app_url_incidents, auth0_audience } from '../../utils/consts';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useGlobalStateApp } from '../utils/globalStateProviderApp'
import Footer from '../../components/Footer';
import { UserHasPermissionsFor, UserHasPermissionsForContracts, UserHasPermissionsForIncidents, analyticsEvent, getAuth0IsAuthenticatedStatus, getAuth0Token, getDoorStateByInstallation, getIncidentsByAssetId,  getLangByLocalStorage,  getStatus, instanceOfDeviceShadow, scrollTop, setDocumentTitle } from '../../utils/helpers';
import { useAuth0 } from '@auth0/auth0-react';
import './InstallationDetail.css';
import HeaderDetail from '../../components/HeaderDetail';
import DoorState from '../components/detail/DoorState';
import { getCategoriesForLibrary, getLibraryForEntity } from '../../documents/utils/helpersFetch';
import InformationDetails, { getInformationsDetailsDataElement  } from '../../components/InformationDetails';
import { fetchCasesByAssetFabrNumber } from '../../incidents/utils/helpersFetch';
import OpenCase from '../components/detail/OpenCase';
import PopupWrapper from '../../components/PopupWrapper';
import { Button } from '../../components/atoms/Button';
import { Case } from '../../incidents/interfaces/Case';
import TableInsights from '../components/detail/TableInsights';
import TimePeriodLable from '../components/detail/TimePeriodLable';
import DoorOpenClosingsWrapper from '../components/detail/DoorOpenClosingsWrapper';
import EnergyChartWrapper from '../components/detail/EnergyChartWrapper';
import TurnsChartWrapper from '../components/detail/TurnsChartWrapper';
import DoorBlockagesChartWrapper from '../components/detail/DoorBlockagesChartWrapper';
import TurnsBetweenLevelsChartWrapper from '../components/detail/TurnsBetweenLevelsChartWrapper';
import TableEventsWrapper from '../components/detail/TableEventsWrapper';
import { DoorCyclesCount } from '../../interfaces/DoorCyclesCount';

const InstallationDetail = () => {  
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();
  const { getAccessTokenSilently,isAuthenticated } = useAuth0();

  const [mqttConnectionLost,setMqttConnectionLost] = useState(true)
  const [hideLiveInformations,setHideLiveInformations] = useState(false)
  const [currentInstallation, setCurrentInstallation] = useState<Installation | null>(null);
  const [currentDevice, setCurrentDevice] = useState<DeviceInfo | null>(null);
  const [currentIncidents, setCurrentIncidents] = useState<Case[] | undefined>(undefined);
  const [storedFeedMessages, setStoredFeedMessages] = useState<FeedMessageDictionary>({});

  const [pastEvents, setPastEvents] = useState<[]>([])
  const [speakingTimePeriod, setSpeakingTimePeriod] = useState<string>('last7days')
  const [isLoadingPastEvents, setIsLoadingPastEvents] = useState(false)
  const [doorState,setDoorState] = useState<any>()
  
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment().subtract(7, 'days').startOf('day').toISOString(),
    endDate: moment().toISOString(),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOpenCaseDialog, setShowOpenCaseDialog] = useState<boolean>(false)
  const [documentsCount, setDocumentsCount] = useState<number|null>(null);
  const [contractsCount, setContractsCount] = useState<number|null>(null);
  const [incidentsCount, setIncidentsCount] = useState<number|null>(null);
  const [tiles, setTiles] = useState<ReactNode>(<></>);
  const [deviceInfo, setDeviceInfo] = useState<null | DeviceInfoResponse>(null);
  const [lastDeviceErrorStateByLivefeed, setLastDeviceErrorStateByLivefeed] = useState<string |undefined>(undefined);
  const { installationId,organizationName } = useParams();
  const [deviceShadow, setDeviceShadow] = React.useState<DeviceShadow | undefined>(undefined);
 
  const [todayDoorCycles, setTodayDoorCycles] = useState<string>('-');
  const [todayRuns, setTodayRuns] = useState<string>('-');
  const [dynHeadeContent, setDynHeadeContent] = useState<ReactNode>(<></>);
  const [dynHeadeContentMobile, setDynHeadeContentMobile] = useState<ReactNode>(<></>);
  const arrow = <svg className="h-4 fill-blueDark group-hover:fill-white  mb-2" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6043 0.791992L17.5481 2.84824L24.2272 9.54199H0.645996V12.4587H24.2272L17.5335 19.1524L19.6043 21.2087L29.8127 11.0003L19.6043 0.791992Z" /></svg>
  const arrowInactive = <svg className="h-4 fill-greyLight mb-2" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6043 0.791992L17.5481 2.84824L24.2272 9.54199H0.645996V12.4587H24.2272L17.5335 19.1524L19.6043 21.2087L29.8127 11.0003L19.6043 0.791992Z" /></svg>
  const [userHasPermissionIncidents, setUserHasPermissionIncidents] = React.useState<boolean>(false)

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let lang = getLangByLocalStorage()
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }

    const loadAccessToken = async () => {
      const accessToken =  await getAuth0Token(getAccessTokenSilently)
      UserHasPermissionsFor('Incidents',accessToken)?setUserHasPermissionIncidents(true):setUserHasPermissionIncidents(false)
    }
    loadAccessToken()
   
    return () => {
      setDocumentTitle()
    };
  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
      if(currentInstallation){
        setDocumentTitle(currentInstallation)
      }
      else{
        setDocumentTitle()
      }
    }
  }, [state.language]);

  useEffect(() => {
    if(installationId){
      loadInstallation(installationId)

      // load incidents data for installations
      const getInstallationIncidentStatusAsync = async () => {
        const accessToken =  await getAuth0Token(getAccessTokenSilently)
        let incidentsByAsset = await getIncidentsByAssetId(installationId,accessToken)
        if(incidentsByAsset && incidentsByAsset[installationId]){
          setCurrentIncidents(incidentsByAsset[installationId])
        }
      }
      getInstallationIncidentStatusAsync()
    }
  }, [installationId]);

  useEffect(() => {
    setDynHeadeContent(<>
      <div className="flex">
        {todayRuns !== "-" && <div className=" w-1/2 pr-4">
          <h3 className=" uppercase text-white">{I18n.get('runsTotalDetailHead')}</h3>
          <h2 className=" text-white">{todayRuns}</h2>
        </div>}
        {todayDoorCycles !== "-" && <div className=" w-1/2 pr-4">
          <h3 className=" uppercase text-white">{I18n.get('doorCyclesTotalDetailHead')}</h3>
          <h2 className=" text-white">{todayDoorCycles}</h2>
        </div>}
      </div>
    </>
    )
    setDynHeadeContentMobile(<>
      {todayRuns !== "-" && <div className="w-1/2">
        <div className="">{I18n.get('runsTotalDetailHead')}</div>
        <div className="">{todayRuns}</div>
      </div>}
      {todayDoorCycles !== "-" && <div className="w-1/2">
        <div className="">{I18n.get('doorCyclesTotalDetailHead')}</div>
        <div className="">{todayDoorCycles}</div>
      </div>}
    </>)
  }, [todayRuns,todayDoorCycles]);
  

  const loadInstallation = async (id:string) => {
    setIsLoading(true)
    
    let accessToken =  await getAuth0Token(getAccessTokenSilently)
    let installationResponse = await fetchInstallation(id,accessToken)
    if(installationResponse.status === 200){
      const installation = await installationResponse.json()
      setCurrentInstallation(installation);
    }
    else{
      const resonseMessage = await installationResponse.json()
      navigate('/404')
    }
    setIsLoading(false)
  }

  const fetchDevice = async () => {

    let deviceId =  currentInstallation?.iotBoxes[0]

    if (deviceId ) {
      setIsLoading(true);
      let accessToken =  await getAuth0Token(getAccessTokenSilently)
      const [deviceInfo] = await fetchAllDeviceData(deviceId,accessToken, dateRange);

        if(!deviceInfo.device || deviceInfo.device.ragMqtt === "1"){
          setMqttConnectionLost(true)
        }
        else{
          setMqttConnectionLost(false)
          loadPastEvents(deviceId ,moment().subtract(7, 'days').toISOString(),moment().toISOString(),setIsLoadingPastEvents,setPastEvents,accessToken)  
        }
      
      setDeviceInfo(null)

      setOrReportError(setDeviceInfo, deviceInfo);
      setIsLoading(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  };

  useEffect(() => {
    if(deviceInfo && currentInstallation){

      const doSocket = async () => {

        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        const socket = io(process.env.REACT_APP_API_URL ?? 'http://localhost:8080', {
          auth: {
            token: `${accessToken}`,
          },
          transports: ['websocket'],
        });
        socket.emit('get-live-feed', currentInstallation.iotBoxes[0]   );
        socket.on('live-feed-event', (data: any) => {
          if(instanceOfDeviceShadow(data)){
            setDeviceShadow(data)
          }
          else{
            //console.log(data)
            if(deviceInfo && deviceInfo.device){
              if(deviceInfo && !(deviceInfo.device.deviceId in storedFeedMessages)){
                storedFeedMessages[deviceInfo.device.deviceId] = []
              }
              storedFeedMessages[deviceInfo.device.deviceId] = [data,...storedFeedMessages[deviceInfo.device.deviceId]].slice(0,100)  
              setStoredFeedMessages(JSON.parse(JSON.stringify(storedFeedMessages)) )
            }
          }
        });
      }
      doSocket()
    }
    
  }, [deviceInfo,currentInstallation]);

  useEffect(()=>{
    if(currentInstallation && deviceShadow){
      setDoorState(getDoorStateByInstallation(null,currentInstallation,null, deviceShadow))
    }
    else{
      setDoorState(<Spin size="large" spinning={true} key="spin"><DoorState doorCount={ 0 } doorSouthOpen={false} doorNorthOpen={false} /></Spin>)
    }
  },[deviceShadow,currentInstallation,state.language])
  
  useEffect(()=>{
    
    if(!currentInstallation)
    {
      return 
    }

    const loadDevice = async (currentInstallation:Installation) => {

      let accessToken =  await getAuth0Token(getAccessTokenSilently)
      const devicesStatus = await fetchDevicesStatus([currentInstallation.iotBoxes[0]],accessToken);
      if(devicesStatus[0]){
        setCurrentDevice(devicesStatus[0].device)
      }
    }

    const loadDocuments = async (currentInstallation:Installation) => {
      const documentsLibrary = await getLibraryForEntity(currentInstallation._id,"Asset",await getAuth0Token(getAccessTokenSilently));
      if(documentsLibrary && documentsLibrary.count > 0){
        documentsLibrary.libraries.forEach(async (lib)=>{
          var count = 0 
          if(lib.title==='sf_asset_lib'){
            const categories = await getCategoriesForLibrary(lib.id,await getAuth0Token(getAccessTokenSilently))
            categories?.forEach((cat)=>{
                count += cat.count
            })
          }
          setDocumentsCount(count)
        })
      }
      else{
        setDocumentsCount(0)
      }
    }

    const loadContracts = async (currentInstallation:Installation) => {
      let hasP =  await UserHasPermissionsForContracts(await getAuth0Token(getAccessTokenSilently))
      if(hasP && currentInstallation.contracts.length > 0){
        setContractsCount(1)
      }
    }

    const loadIncidents = async (currentInstallation:Installation) => {

      let hasP =  await UserHasPermissionsForIncidents(await getAuth0Token(getAccessTokenSilently))
      if(hasP ){
        try{
          const pagedIncidents = await fetchCasesByAssetFabrNumber(currentInstallation, await getAuth0Token(getAccessTokenSilently));  
          if(pagedIncidents){
            setIncidentsCount(pagedIncidents.totalCount)
          }  
        }
        catch(error){
          console.log(error)
        }
      }
    }
    
    loadDocuments(currentInstallation)
    loadContracts(currentInstallation)
    loadDevice(currentInstallation)
    loadIncidents(currentInstallation)
    
  },[currentInstallation])

  useEffect(()=>{
    setAppTiles()
  },[incidentsCount,documentsCount,contractsCount,state.language])
  
  useEffect(()=>{
    if(currentDevice){
      setHideLiveInformations(false)
      if(currentDevice.ragMqtt === "1" || currentDevice.ragGlobal === "1"){
        //setHideLiveInformations(true)
      }
    }
    else{
      setHideLiveInformations(true)
    }
    if(currentIncidents){
      setHideLiveInformations(true)
    }
  },[currentDevice,currentIncidents])

  useEffect(()=>{
    
    if(currentInstallation){
      let doorStateByFeedMessages = getDoorStateByInstallation(deviceInfo,currentInstallation,storedFeedMessages, null)
      if(doorStateByFeedMessages){
        setDoorState(doorStateByFeedMessages)
      }
    }

    if(storedFeedMessages && deviceInfo?.device && storedFeedMessages[deviceInfo.device.deviceId] && storedFeedMessages[deviceInfo.device.deviceId][0]){
      let lastMessage = storedFeedMessages[deviceInfo.device.deviceId][0]
      if(lastMessage){
        if(lastMessage.body?.name === 'deviceErrorState' && (lastMessage.body.attributes?.errorState === 1 || lastMessage.body.attributes?.errorState === 0 )){
          setLastDeviceErrorStateByLivefeed(lastMessage.body.attributes.errorState.toString())
        }
      }
    }
  },[storedFeedMessages])

  useEffect(() => {
    if (currentInstallation) {
      // todo
      fetchDevice()
      // load daily values
      const loadDailyVAlue = async() => {
      
        const queryString = dateRange ? `?startDate=${moment().startOf('day').format('YYYY-MM-DDT00:00:00.000')}&endDate=${moment().endOf('day').format('YYYY-MM-DDT23:59:59.999')}` : '';
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        let runsTodayResponse = await fetchRunsOverTime(currentInstallation.iotBoxes[0], queryString,accessToken)

        let cyclesTodayResponse = await fetchDoorCyclesOverTime(currentInstallation.iotBoxes[0], queryString,accessToken)

        let countRuns = 0
        if(Array.isArray(runsTodayResponse)){

          runsTodayResponse.forEach((elem) => {
            if(elem.runcount){
              countRuns += elem.runcount
            }
          })

          if(runsTodayResponse && runsTodayResponse.length > 0){
            setTodayRuns(countRuns.toString())
          }

        }

        if(!cyclesTodayResponse ||  !Array.isArray(cyclesTodayResponse) || cyclesTodayResponse.length <= 0){
          // nothing
        }
        else{
          var doorCyclesCountResult:DoorCyclesCount = {
            closingCount:0,
            doorCycleCount:0,
            openingCount:0
          }
          cyclesTodayResponse.forEach(item => {
            if(item.closingCount && item.closingCount > 0){
              doorCyclesCountResult.closingCount += item.closingCount
            }
            if(item.doorCycleCount && item.doorCycleCount > 0){
              doorCyclesCountResult.doorCycleCount += item.doorCycleCount
            }
            if(item.openedCount && item.openedCount > 0){
              doorCyclesCountResult.openingCount += item.openedCount
            }
            else if(item.openingCount && item.openingCount > 0){
              doorCyclesCountResult.openingCount += item.openingCount
            }
          })
          setTodayDoorCycles(doorCyclesCountResult.doorCycleCount.toString())
        }
      }
      loadDailyVAlue()
      setDocumentTitle(currentInstallation)
    }
  }, [currentInstallation]);

  useEffect(() => {
    if (currentInstallation) {
      fetchDevice();
    }
  }, [dateRange]);

  const goBack = () => {
    scrollTop()
    let urlParams = '?'
    +'page='+(stateApp.search?.page ? stateApp.search?.page : 1)
    +'&search='+(stateApp.search?.searchterm ? encodeURIComponent(stateApp.search.searchterm) : '')
    +'&sortBy='+(stateApp.search?.sortBy ? stateApp.search.sortBy : '')
    +'&filterBy='+(stateApp.search?.filterBy ? stateApp.search.filterBy : '')
    +'&back=true'

    console.log("Navigate back with these params:", urlParams)

    navigate('/org_'+state.organizationName+'/welcome'+urlParams)
  }

  const getMaintananceData = (installation:Installation | null) => {

    let arrData:JSX.Element[] = []

    if(installation){ 
      if(installation.lastDateOfMaintenance){ arrData.push(getInformationsDetailsDataElement('LastDateOfMaintenance', moment(installation.lastDateOfMaintenance).format('DD.MM.YYYY'),'devices')) }
      if(installation.nextDateOfMaintenance){ arrData.push(getInformationsDetailsDataElement('NextDateOfMaintenance', moment(installation.nextDateOfMaintenance).format('DD.MM.YYYY'),'devices')) }
      if(installation.maintenanceInterval){ arrData.push(getInformationsDetailsDataElement('MaintenanceInterval',installation.maintenanceInterval + ' ' + I18n.get('Months'),'devices')) }

      if(installation.lastDateOfMainAudit){ arrData.push(getInformationsDetailsDataElement('LastDateOfMainAudit',moment(installation.lastDateOfMainAudit).format('DD.MM.YYYY'),'devices')) }
      if(installation.nextDateOfMainAudit){ arrData.push(getInformationsDetailsDataElement('NextDateOfMainAudit', moment(installation.nextDateOfMainAudit).format('DD.MM.YYYY'),'devices')) }
      if(installation.auditInterval){ arrData.push(getInformationsDetailsDataElement('AuditInterval',installation.auditInterval,'devices')) }

      if(installation.lastDateOfInterimAudit){ arrData.push(getInformationsDetailsDataElement('LastDateOfInterimAudit',moment(installation.lastDateOfInterimAudit).format('DD.MM.YYYY'),'devices')) }
      if(installation.nextDateOfInterimAudit){ arrData.push(getInformationsDetailsDataElement('NextDateOfInterimAudit', moment(installation.nextDateOfInterimAudit).format('DD.MM.YYYY'),'devices')) }

    }
    
    return arrData
  }

  const getInstallationData = (installation:Installation | null) => {

    let arrData:JSX.Element[] = []

    if(installation){ 
      if(installation.yearOfConstruction){ arrData.push(getInformationsDetailsDataElement('constructionYear',installation.yearOfConstruction.toString(),'devices')) }
      if(installation.elevatorConstruction){ arrData.push(getInformationsDetailsDataElement('elevatorType',installation.elevatorConstruction,'devices')) }
      if(installation.elevatorType){ arrData.push(getInformationsDetailsDataElement('liftType',installation.elevatorType,'devices')) }
      if(installation.numberOfStops){ arrData.push(getInformationsDetailsDataElement('floors',installation.numberOfStops.toString(),'devices')) }
      if(installation.numberOfDoors){ arrData.push(getInformationsDetailsDataElement('doors',installation.numberOfDoors.toString(),'devices')) }
      if(installation.loadCapacityKg){ arrData.push(getInformationsDetailsDataElement('loadKG',installation.loadCapacityKg.toString(),'devices')) }
      if(installation.maxPersons){ arrData.push(getInformationsDetailsDataElement('loadPersons',installation.maxPersons.toString(),'devices')) }
      if(installation.mainAccess){ arrData.push(getInformationsDetailsDataElement('mainEntrance',installation.mainAccess.toString(),'devices')) }
      if(installation.speedMs){ arrData.push(getInformationsDetailsDataElement('speedMS',installation.speedMs.toLocaleString('de-DE'),'devices')) }
      if(installation.controllerVariant){ arrData.push(getInformationsDetailsDataElement('Controller',installation.controllerVariant,'devices')) }
    }
    
    return arrData
  }

  const linkOutToDocuments = () => {
    document.location.href = app_url_documents
    + '/' + organizationName 
    + '/installations' 
    + '/' +installationId
    + '/?lang=' + state.language
  }

  const linkOutToContracts = () => {
    document.location.href = app_url_contracts
    + '/' + organizationName
    + '/contract'
    + '/' +currentInstallation?.contracts[0]
    + '/?lang=' + state.language
  }

  const linkOutToIncidents = () => {
    document.location.href = app_url_incidents
    + '/' + organizationName 
    + '/welcome'
    + '?page=1&search=' + currentInstallation?.label
    + '&lang=' + state.language
  }

  const linkOutToSingleIncidents = (incidentId:string) => {
    document.location.href = app_url_incidents
    + '/' + organizationName 
    + '/incidents/' + incidentId
    + '?lang=' + state.language
  }

  const setAppTiles = async () => {

    let appTiles:any[] = []

    let accessToken = await getAuth0Token(getAccessTokenSilently)
  
    if(UserHasPermissionsFor('Contracts',accessToken))
      appTiles.push( getTile( contractsCount,I18n.get('Contracts'),linkOutToContracts ) )
    
    if(UserHasPermissionsFor('Documents',accessToken))
      appTiles.push( getTile( documentsCount,I18n.get('Documents'),linkOutToDocuments ) )
  
    if(UserHasPermissionsFor('Incidents',accessToken))
      appTiles.push( getTile( incidentsCount,I18n.get('Incidents'),linkOutToIncidents ) )
    
    if(appTiles.length > 0){
      setTiles(<div className=" pt-16 mt-10 grid  s:grid-cols-2 m:grid-cols-2 l:grid-cols-4 xl:grid-cols-4 gap-4">{appTiles}</div>)
    }
    else{
      setTiles(<></>)
    }
  }

  const getTile = (counter:number|null,label:string,opener:Function) => {

      let cssClassesOuterContainerActive = '  transition duration-700 bg-blueBaby     h-36 l:h-192px flex items-end justify-between p-6 cursor-pointer hover:bg-blueDark group'
      let cssClassesOuterContainerInActive = 'transition duration-700 bg-greyLighter  h-36 l:h-192px flex items-end justify-between p-6 cursor-default text-greyLight'
      
      let cssClassesH2ContainerActive = '   w-min inline-block pr-3 group-hover:text-white'
      let cssClassesH2ContainerInActive = ' w-min inline-block pr-3 text-greyLight'

      return <div data-testid={`test_app_tiles_item_${label}`} key={label} onClick={counter === null || counter === 0? ()=>{} : ()=>opener()} className={ (counter === 0) ? cssClassesOuterContainerInActive : cssClassesOuterContainerActive }>
        <div className="flex items-end pr-4"><h2 className={ (counter === 0) ? cssClassesH2ContainerInActive : cssClassesH2ContainerActive }>{label}</h2> { (counter === 0) ? arrowInactive:arrow }</div>
        <h2 className={ (counter === 0) ? 'text-greyLight' : 'group-hover:text-white' }><Spin size="default" spinning={(counter === null) ? true : false} key="spin">{counter!== null?counter:'-'}</Spin></h2>
      </div>
  }

  const goToIncidents = () => {
    if(userHasPermissionIncidents && currentIncidents){
      if(currentIncidents.length == 1){
        linkOutToSingleIncidents(currentIncidents[0]._id)
      }
      else{// more than one
        linkOutToIncidents()
      }
    }
  }

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      {currentInstallation && <HeaderDetail 
      mode='Installation' 
      goBack={goBack} 
      installation={currentInstallation} 
      loadInstallation={loadInstallation} 
      dynContent={dynHeadeContent} 
      dynContentMobile={dynHeadeContentMobile}
      contract={null}
      />}
      <section className="Content text-blueDark">
        {tiles}
        {currentInstallation && (hideLiveInformations || currentDevice === null) && <div className="w-full text-right pt-10"><Button
                  disabled={false}
                  theme='light'
                  size='medium'
                  ownStyle=''
                  onClick={()=>{ setShowOpenCaseDialog(true)}}
                  label={ I18n.get('Btn_Report_a_problem') } /></div>
                  }
        {currentInstallation && showOpenCaseDialog && <PopupWrapper mobileTitle={I18n.get('Report_a_problem_mobile')} closerFunction={()=>setShowOpenCaseDialog(false)} children={<OpenCase installation={currentInstallation} closerFunction={()=>setShowOpenCaseDialog(false)}  />} /> }
        {<InformationDetails data={getInstallationData( currentInstallation )} headline='Details' bottomMargin={true} />}
        {<InformationDetails data={getMaintananceData( currentInstallation )} headline={I18n.get('Maintanance')} />}
        {currentInstallation?.iotBoxes && currentInstallation.iotBoxes.length > 0 && <div>
          <div className="flex pt-24 xxl:pt-48 justify-between flex-col m:flex-row">
          <h1 className=" m:self-end mb-0">Live-Tracking</h1>
          {currentInstallation && (!hideLiveInformations || currentDevice !== null) && <div className=" text-right pt-10"><Button
                  disabled={false}
                  theme='light'
                  size='medium'
                  ownStyle=''
                  onClick={()=>{ setShowOpenCaseDialog(true)}}
                  label={ I18n.get('Btn_Report_a_problem') } /></div>
                  }

        </div>
        <div className="pt-16 hidden m:flex overflow-hidden">
          <div className="flex flex-col items-center m:mr-10 xxl:mr-52">
            <h3 className="h-20 uppercase ">{I18n.get('state')}</h3>
            <div className={((userHasPermissionIncidents && currentIncidents)? " cursor-pointer":"") + " w-32 h-32  flex flex-col justify-center items-center "} onClick={()=>goToIncidents()}>
              <div className=" ">{getStatus(currentIncidents,currentDevice, ' w-14 ',false,undefined,currentDevice?true:false,lastDeviceErrorStateByLivefeed )}</div>
              <div className=" text-center pt-3">{getStatus(currentIncidents,currentDevice,' w-14 ',false,true,currentDevice?true:false,lastDeviceErrorStateByLivefeed)}</div>
            </div>
          </div>
          <div className={hideLiveInformations? ' hidden ' : ''}>{doorState}</div>
          <div className={mqttConnectionLost || hideLiveInformations ? " hidden" : " ml-20 xxl:ml-48" }>
            {<LevelVisualization devicesShadow={deviceShadow} device={deviceInfo?.device} liveMessages={deviceInfo?.device && storedFeedMessages[deviceInfo.device.deviceId]} />}
          </div>
        </div>
        <div className="flex pt-6 m:hidden">
          <div className="flex flex-col text-base w-full">
            <div className="flex w-full items-center">
              <div className="w-2/3">{I18n.get('State')}
                <div className=" -ml-4">{getStatus(currentIncidents,currentDevice,' w-14 ',false,true,currentDevice?true:false,lastDeviceErrorStateByLivefeed)}</div>
              </div>
              <div className="w-1/3 pl-2">{getStatus(currentIncidents,currentDevice, ' w-7 ',false,undefined,currentDevice?true:false,lastDeviceErrorStateByLivefeed)}</div>
            </div>
            <div className={hideLiveInformations ? 'hidden' : "flex mt-6 w-full items-center" }>
              <div className="w-2/3">{I18n.get('door')}</div>
              <div className="w-1/3">{doorState}</div>
            </div>            
            <div className={hideLiveInformations ? 'hidden' : "flex mt-2 w-full items-center"}>
              <div className="w-2/3">{I18n.get('Level')}</div>
              <div className="w-1/3">
                <div className={mqttConnectionLost ? " hidden" : "" }>
                  {<LevelVisualization devicesShadow={deviceShadow} device={deviceInfo?.device} liveMessages={deviceInfo?.device && storedFeedMessages[deviceInfo.device.deviceId]} />}
                </div>                
              </div>
              </div>
            </div>            
          </div>
        </div>}
      </section>

      { currentDevice && <TableInsights device={currentDevice} />}

      {currentInstallation?.iotBoxes && currentInstallation.iotBoxes.length > 0 && <section className="Content text-blueDark"><div className=" ">
          <h1 className=" mb-0">{I18n.get('elevatorAnalysis')}</h1>
        </div></section>}

      {currentInstallation?.iotBoxes && currentInstallation.iotBoxes.length > 0 && <div><section className=" sticky top-16 s:top-16 m:top-24 xl:top-24 xxl:top-28 pl-0 p-6 pr-0  bg-white" style={{zIndex:'5'}}>
        <div className="Content ">
          <div className="  ">
            <Select 
                  className="w-full s:w-48"
                  defaultValue="last7days"
                  //getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownStyle={{
                    zIndex:5
                  }}
                  onChange={(value: string)=>{
                    let startDate = '';
                    let endDate = '';
                    let startFomat = 'YYYY-MM-DDT00:00:00.000'
                    let endFomat = 'YYYY-MM-DDT23:59:59.999'
                    setSpeakingTimePeriod(value)                    
                    switch(value){
                      case 'today':
                        startDate = moment().startOf('day').format(startFomat);
                        endDate = moment().endOf('day').format(endFomat);
                        analyticsEvent({action:'AssetDetailPage',category:'DetailPageTimeSelected',label:'DetailPageTimeSelected today'})
                        break;
                      case 'thisMonth':
                        startDate = moment().startOf('month').format(startFomat);
                        endDate = moment().endOf('month').format(endFomat);
                        analyticsEvent({action:'AssetDetailPage',category:'DetailPageTimeSelected',label:'DetailPageTimeSelected thisMonth'})
                        break;
                      case 'lastMonth':
                        startDate = moment().subtract(1, 'month').startOf('month').format(startFomat);
                        endDate = moment().subtract(1, 'month').endOf('month').format(endFomat);
                        analyticsEvent({action:'AssetDetailPage',category:'DetailPageTimeSelected',label:'DetailPageTimeSelected lastMonth'})
                        break;
                      case 'last7days':
                        startDate = moment().subtract(7, 'days').format(startFomat);
                        endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                        analyticsEvent({action:'AssetDetailPage',category:'DetailPageTimeSelected',label:'DetailPageTimeSelected last7days'})
                        break;
                      case 'last14days':
                        startDate = moment().subtract(14, 'days').format(startFomat);
                        endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                        analyticsEvent({action:'AssetDetailPage',category:'DetailPageTimeSelected',label:'DetailPageTimeSelected last14days'})
                        break;
                      case 'last30days':
                        startDate = moment().subtract(30, 'days').format(startFomat);
                        endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                        analyticsEvent({action:'AssetDetailPage',category:'DetailPageTimeSelected',label:'DetailPageTimeSelected last30days'})
                        break;
                      case 'last60days':
                        startDate = moment().subtract(60, 'days').format(startFomat);
                        endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                        analyticsEvent({action:'AssetDetailPage',category:'DetailPageTimeSelected',label:'DetailPageTimeSelected last60days'})
                        break;
                    }

                    if (startDate && endDate) {
                      setDateRange({ startDate, endDate });
                    }          
                  }}
                  loading={false}
                  options={[
                    {
                      value: 'today',
                      label: <TimePeriodLable translateKey='today' />,
                    },
                    {
                      value: 'thisMonth',
                      label: <TimePeriodLable translateKey='thisMonth' />,
                    },
                    {
                      value: 'lastMonth',
                      label: <TimePeriodLable translateKey='lastMonth' />,
                    },
                    {
                      value: 'last7days',
                      label: <TimePeriodLable translateKey='last7days' />,
                    },
                    {
                      value: 'last14days',
                      label: <TimePeriodLable translateKey='last14days' />,
                      
                    },
                    {
                      value: 'last30days',
                      label: <TimePeriodLable translateKey='last30days' />,
                    },
                    {
                      value: 'last60days',
                      label: <TimePeriodLable translateKey='last60days' />,
                    },
                  ]}
                />            
          </div>
        </div>
      </section>
      
      <EnergyChartWrapper dateRange={dateRange} device={currentDevice} speakingTimePeriod={speakingTimePeriod} />

      <TurnsChartWrapper dateRange={dateRange} device={currentDevice} speakingTimePeriod={speakingTimePeriod} />

      <TurnsBetweenLevelsChartWrapper dateRange={dateRange} device={currentDevice} speakingTimePeriod={speakingTimePeriod} />

      <DoorOpenClosingsWrapper dateRange={dateRange} deviceId={currentDevice?._id} speakingTimePeriod={speakingTimePeriod} />

      <DoorBlockagesChartWrapper  dateRange={dateRange} device={currentDevice} speakingTimePeriod={speakingTimePeriod} />

      <TableEventsWrapper device={currentDevice} speakingTimePeriod={speakingTimePeriod} storedFeedMessages={currentDevice? storedFeedMessages[currentDevice.deviceId]: []} pastEvents={pastEvents}/>
      
      </div>}

      {!(currentInstallation?.iotBoxes && currentInstallation.iotBoxes.length > 0) && <div className=" pt-10 xxl:pt-20"></div>}

      <Footer />
    </div>
  );
};

export default InstallationDetail;