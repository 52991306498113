import ReactEChartsCore from 'echarts-for-react/lib/core';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import { Spin } from 'antd';
const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { SankeyChart } from 'echarts/charts';
// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import { useEffect, useState } from 'react';
import { RunBetweenFloorsResponse } from '../../../interfaces/RunBetweenFloors';
import { DeviceInfo } from '../../../interfaces/DeviceInfo';
import { useGlobalState } from '../../../utils/globalStateProvider';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import { strings as stringGlobal } from './../../../utils/globalLocalization'
import React from 'react';

I18n.putVocabularies(stringGlobal);

function TurnsBetweenLevelsChart(props: {
  response: RunBetweenFloorsResponse | null;
  isLoading: boolean;
  device: DeviceInfo | null;
}) {
  interface dataItem {
    label: string;
    from: string;
    to: string;
    value: string;
  }

  interface result {
    data: Array<dataItem>;
    topMaxValue?: string;
  }

  const [chartLabels, setChartLabels] = useState([{}]);
  const [chartData, setChartData] = useState([{}]);
  const [noData, setNoData] = useState(false);
  const { state } = useGlobalState()
  const eChartsRef = React.useRef(null as any)
  const [chartIsReady, setChartIsReady] = useState(false)

  echarts.use([TitleComponent, TooltipComponent, GridComponent, CanvasRenderer, LegendComponent, SankeyChart]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [ state.language]);  

  useEffect(() => {
    if (eChartsRef && eChartsRef.current){
      eChartsRef.current?.getEchartsInstance().setOption(getOption());
    }

  }, [chartData,chartIsReady,eChartsRef,props.isLoading,state.language]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (eChartsRef && eChartsRef.current){
        // stop interval and init chart
        setChartIsReady(true)
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let reportedFloors = 255;

    if(!props.device || !props.response || !Array.isArray(props.response) || props.response.length <= 0){
      setNoData(true);
      return
    }

    // catch the case of only 1 entry with levelruns
    if(props.response.length == 1 
      && props.response[0].runEndFloor == props.response[0].runStartFloor){
        setNoData(true);
        return
    }

    if (props.device.mapping) {
      reportedFloors = Object.keys(props.device.mapping).length
    }

    if(reportedFloors <= 1 || reportedFloors == 255){
      setNoData(true);
      return 
    }

    const data = props.response;
    const myResult = { data: [] } as result;
    const arrayOfObjects = Object.values(data);
    if (arrayOfObjects.length > 0) {
      // prepare datapoints
      arrayOfObjects.forEach((element) => {
        const dataPoint = element;
        if (dataPoint.runStartFloor !== 255 && dataPoint.runEndFloor !== 255) {
          myResult.data?.push({
            label:
              dataPoint.runStartFloor +
              ' - ' +
              dataPoint.runEndFloor,
            from: dataPoint.runStartFloor.toString(),
            to: dataPoint.runEndFloor.toString(),
            value: dataPoint.runCount.toString(),
          });
        }
      });

      const dataLinks: {
        source: string;
        target: string;
        value: string;
      }[] = [];
      for (let i = 0; i < arrayOfObjects.length; i++) {
        if (myResult.data[i].from !== myResult.data[i].to) {
          dataLinks.push({
            source: props.device.mapping[myResult.data[i].from] + '_in',
            target: props.device.mapping[myResult.data[i].to] + '_out',
            value: myResult.data[i].value,
          });
        }
      }

      setChartData(dataLinks);

      const dataLabels: {
        name: string;
        itemStyle: {
          color: string;
          borderColor: string;
        };
        depth: number;
      }[] = [];

      for (let i = 0; i < reportedFloors; i++) {
          dataLabels.push({
            name: props.device.mapping[i] + '_in',
            itemStyle: {
              color: colorsTheme.chartMainColor,
              borderColor: colorsTheme.chartMainColor,
            },
            depth: 0,
          });
          dataLabels.push({
            name: props.device.mapping[i] + '_out',
            itemStyle: {
              color: colorsTheme.chartMainColor,
              borderColor: colorsTheme.chartMainColor,
            },
            depth: 1,
          });
        }

        setChartLabels(dataLabels);
      setNoData(false);
    } else {
      setNoData(true);
    }

  }, [props.response, props.device,state.language,chartIsReady,eChartsRef]);

  const getOption = () => {
    return {
      series: {
        type: 'sankey',
        draggable: false,
        layoutIterations: 0,
        left: 10.0,
        top: 20.0,
        right: 17.0,
        bottom: 25.0,
        data: chartLabels.reverse(),
        links: chartData,
        lineStyle: {
          color: colorsTheme.chartMainColor,
          curveness: 0.5,
        },
        itemStyle: {
          color: colorsTheme.chartMainColor,
          borderColor: colorsTheme.chartMainColor,
        },
        label: {
          color: '#000',
          fontFamily: 'Arial',
          fontSize: 10,
          formatter: function (value: { name: string }) {
            if (value.name) {
              return value.name.split('_')[0];
            }
          },
        },
      },
      tooltip: {
        trigger: 'item', // 'item|axis|none'
        triggerOn: 'mousemove',
        backgroundColor:colorsTheme.chartTooltipBackground,
        textStyle:{
          color:colorsTheme.chartTooltipTextColor
        },
        borderWidth:0,
        formatter: function (data: { value: number }) {
          if (data && data.value) {
            if (data.value <= 0) {
              return '-';
            } else {
              return String(data.value);
            }
          } else {
            return '-';
          }
        },
      },
    }
  };

  return (
    <>
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
        <Spin size="large" spinning={props.isLoading}>
          <ReactEChartsCore
            echarts={echarts}
            option={{}}
            notMerge={true}
            lazyUpdate={true}
            theme={'light'}
            showLoading={false}
            ref={eChartsRef}
            style={{ height: '300px', width: '100%' }}
          />
        </Spin>
      )}
    </>
  );
}
export default TurnsBetweenLevelsChart;
