export const strings = {
    en: {
        Copied:'Copied!',
        CopyName:'Copy name',
        CopyLink:'Copy link',
        RecentlyViewed:'Recently viewed',
        RecentlyUpdated:'Recently updated',
        Actions:'Actions',
        Title: 'Title',
        Categories: 'Categories',
        Documents: 'Documents',
        renameInstallation: 'Rename installation',
        elevatorLocation: 'Elevator location',
        cycles: 'cycles',
        Cycles: 'Cycles',
        logout: 'Log Out',
        login: 'login',
        backLabel: 'Back',
        search: 'Search',
        elevator: 'Elevator',
        address: 'Address',
        door: 'Door',
        runs: 'Runs',
        openings: 'Openings',
        closings: 'Closings',
        blockages: 'Blockages',
        loadErrorInstallation: 'Loading of installation fails.',
        connectedToElevator: 'Connected to elevator',
        notConnectedToElevator: 'Lost elevator connection',
        noCloudConnection: 'Lost cloud connection',
        errorControl: 'Elevator reported an error',
        noBoxInstalled: "No box installed",
        incidentExists: "Open incident",
        MachineUnderService: "Machine under service",
        warningControl: 'Elevator reported an warning',
        tries: 'Tries',
        duration: 'Duration',
        date: 'Date',
        Date: 'Date',
        time: 'Time',
        Time: 'Time',
        event: 'Event',
        messageLogout: 'You successfully logged out!',
        messageGoTo: 'Click here to ',
        messageRedirect: 'You will be redirected...',
        takeOver: 'Apply',
        last7days: 'Last 7 days',
        last14days: 'Last 14 days',
        last30days: 'Last 30 days',
        last60days: 'Last 60 days',
        today: 'Today',
        thisMonth: 'This month',
        lastMonth: 'Last month',
        WeStillCollectData: 'We just have partial data',
        noDataForTimePeriod: 'No data available yet.',
        noData: 'No data available yet.',
        total: 'Total',
        errorOccurred: 'An error has occurred!',
        auth0ErrorMessageNotPartOfOrg: 'This user is not part of the ###orgName### Organization.',
        State: 'State',
        state: 'State',
        level: 'level',
        Level: 'Level',
        supportWelcome: 'Welcome to the Aufzughelden customer dashboard',
        supportSubline: 'Customer service hotline',
        supportMailSubline: 'Customer Service Mail Support',
        tooManyCompareSelectedPre: 'You have already selected ',
        tooManyCompareSelectedPost: ' elevators to compare.',
        overview: "Overview",
        noFiles:'This folder is empty',
        gettingRedirected:'You are getting redirected.',
        Unknown:'Unknown',
        Contract:'Contract',
        Incidents:'Incidents',
        open: 'Open',
        recentlyClosed: 'Recently closed',
        Installation:'Installation',
        Added:'Added',
        AppTitleDevices:'IoT-Dashboard | Aufzughelden by Digital Spine',
        AppTitleDevicesWithInstallatiion:'Asset ###name### | Aufzughelden by Digital Spine',
        AppTitleDocuments:'Documents | Aufzughelden by Digital Spine',
        AppTitleLearn:'Learn | Aufzughelden by Digital Spine',
        AppTitleIncidents:'Incidents | Aufzughelden by Digital Spine',
        AppTitlePortal:'Aufzughelden by Digital Spine',
        AppTitleContracts:'Contracts | Aufzughelden by Digital Spine',
        fabricationNumber:'Fabrication number',
        SubCategory:'Subcategory',
        UnknownContractType : 'Unknown contract type',
        'ContractTypeCooperation' : 'Cooperation',
        'ContractTypeCustomer - IoT only' : 'IoT',
        'ContractTypeCustomer - Maintenance' : 'Maintenance',
        'ContractTypeCustomer - Software/Consulting/Service' : 'Software/Consulting/Service',
        'ContractTypeServicepartner' : 'Servicepartner',
        'ContractStatus_Draft':'Draft',
        'ContractStatus_Activated':'Activated',
        'ContractStatus_In Approval Process':'Approval',
        Startdate:'Start date',
        Enddate:'End date',
        Costs:'Amount',
        Active:'Status',
        Number:'Number',
        Type:'Type',
        TerminationDate:'Terminationdate',
        ContractNumber:'Contract number',
        AutoRenewal:'Auto renewal',
        Yes:'Yes',
        No:'No',
        BillingCycle:'Billing cycle',
        BillingAddress:'Billing address',
        AccountName:'Account',
        CancellationPeriod:'Cancellation period',
        'BillingCycle_Quarterly':'Quarterly',
        'BillingCycle_Yearly':'Yearly',
        'BillingCycle_Monthly':'Monthly',
        'BillingCycle_One-time':'One-time',
        'CancellationPeriod_--None--':'None',
        'CancellationPeriod_1 month':'1 month',
        'CancellationPeriod_3 months':'3 months',
        'CancellationPeriod_6 months':'6 months',
        'CancellationPeriod_12 months':'12 months',
        MaintenanceInterval:'Maintenance interval',
        LastDateOfMaintenance:'Last maintenance',
        NextDateOfMaintenance:'Next maintenance',
        NextDateOfMainAudit:'Next audit',
        NextDateOfInterimAudit:'Next interim audit',
        AuditInterval:'Audit interval',
        LastDateOfMainAudit:'Last audit',
        LastDateOfInterimAudit:'Last interim audit',
        Maintanance:'Maintenance',
        Maintenance:'Maintenance',
        Months:'Months',
        '24 Months':'24 Months',
        '48 Months':'48 Months',
        Controller:'Controller',
        Last:'Last',
        Next:'Next',
        Cancel:'Cancel',
        Close:'Close',
        Message404:'The requested resource could not be found!',
        Message404Contract:'Contract has not been found!',
        Message404Document:'Document has not been found!',
        Message404Installation:'Installation has not been found!',
        Message404Incident:'Incident has not been found!',
        User: "User",
        UpdatedOn: "Updated on",
        CaseNumber: "Case Number",
        CaseType: "Case Type",
        SignalType: "Type",
        CaseSubject: "Case Subject",
        SignalSubject: "Signal Subject",
        AffectedAssets: "Affected Assets",
        Subject: "Subject",
        Description: "Description",
        Submit:'Submit',
        TableOfContents:'Table of contents',
        NewsAndUpdates:'News and Updates',
        InsightTypes_Information: 'Information',
        InsightTypes_Anomaly:'Anomaly',
        InsightTypes_Insight:'Insight',
        InsightTypes_Performance_Insight:'Performance Insight',
        InsightTypes_Maintenance_Alert:'Maintenance Alert',
        InsightTypes_Safety_Alert:'Safety Alert',
        InsightTypes_Connectivity_Status:'Connectivity Status',
        InsightTypes_Environmental_Conditions:'Environmental Conditions',
        InsightTypes_Usage_Patterns:'Usage Patterns',
        InsightTypes_Energy_Efficiency:'Energy Efficiency',
        InsightTypes_Message_Information: 'Information about the behavior of the device.',
        InsightTypes_Message_Anomaly:'Information about abnormal behavior of the device.',
        InsightTypes_Message_Insight:'Additional insights into the behavior of the device.',
        InsightTypes_Message_Performance_Insight:'Indicates the performance metrics of the device, such as speed, efficiency, or resource utilization.',
        InsightTypes_Message_Maintenance_Alert:'Signals upcoming maintenance needs or scheduled service requirements.',
        InsightTypes_Message_Safety_Alert:'Highlights safety-related issues or potential breaches.',
        InsightTypes_Message_Connectivity_Status:'Shows the current connectivity status, like offline, online, intermittent connectivity, etc.',
        InsightTypes_Message_Environmental_Conditions:'Reports on external conditions affecting the device, such as temperature, humidity, or air quality.',
        InsightTypes_Message_Usage_Patterns:'Summarizes usage statistics, frequent activities, or unusual usage patterns.',
        InsightTypes_Message_Energy_Efficiency:'Monitors energy usage, highlighting opportunities for efficiency improvements.',
        Edit:'Edit',
    },
    de: {
        Copied:'Kopiert!',
        CopyName:'Namen kopieren',
        CopyLink:'Link kopieren',
        RecentlyViewed:'Zuletzt angesehen',
        RecentlyUpdated:'Zuletzt aktualisiert',
        Actions:'Aktionen',
        Title: 'Titel',
        Categories: 'Kategorien',
        Documents: 'Dokumente',
        renameInstallation: 'Anlage umbenennen',
        elevatorLocation: 'Aufzug Standort',
        cycles: 'zyklen',
        Cycles: 'Zyklen',
        logout: 'Abmelden',
        login: 'Login',
        backLabel: 'Zurück',
        search: 'Suche',
        elevator: 'Aufzug',
        address: 'Adresse',
        door: 'Tür',
        runs: 'Fahrten',
        openings: 'Öffnungen',
        closings: 'Schließungen',
        blockages: 'Blockierungen',
        loadErrorInstallation: 'Beim Laden der Installation ist ein Fehler aufgetreten.',
        connectedToElevator: 'Verbindung ist hergestellt',
        notConnectedToElevator: 'Verbindung zur Anlage verloren',
        noCloudConnection: 'Verbindung zur Cloud verloren',
        errorControl: 'Aufzug hat einen Fehler gemeldet',
        noBoxInstalled: "Keine Box installiert",
        incidentExists: "Offener Vorgang",
        MachineUnderService: "Maschine in Wartung",
        warningControl: 'Aufzug hat eine Warnung gemeldet',
        tries: 'Versuche',
        duration: 'Dauer',
        date: 'Datum',
        Date: 'Datum',
        time: 'Uhrzeit',
        Time: 'Zeit',
        event: 'Event',
        messageLogout: 'Sie haben sich erfolgreich ausgeloggt!',
        messageGoTo: 'Hier gehts zum ',
        messageRedirect: 'Sie werden weitergeleitet...',
        takeOver: 'Übernehmen',
        last7days: 'Die letzen 7 Tage',
        last14days: 'Die letzen 14 Tage',
        last30days: 'Die letzen 30 Tage',
        last60days: 'Die letzen 60 Tage',
        today: 'Heute',
        thisMonth: 'Diesen Monat',
        lastMonth: 'Letzten Monat',
        WeStillCollectData: 'Wir haben nur Teildaten.',
        noDataForTimePeriod: 'Bisher sind noch keine Daten vorhanden.',
        noData: 'Bisher sind noch keine Daten vorhanden.',
        total: 'Gesamt',
        errorOccurred: 'Es ist ein fehler aufgetreten!',
        auth0ErrorMessageNotPartOfOrg: 'Der angemeldete Benutzer ist nicht Teil der ###orgName### Organisation.',
        state: 'status',
        State: 'Status',
        level: 'etage',
        Level: 'Etage',
        supportWelcome: 'Willkommen zum Aufzughelden Kundendashboard',
        supportSubline: 'Kundendienst Hotline',
        supportMailSubline: 'Kundendienst Mail-Support',
        tooManyCompareSelectedPre: 'Sie haben bereits ',
        tooManyCompareSelectedPost: ' Aufzüge zum Vergleich ausgewählt.',
        overview: "Übersicht",
        noFiles:'Dieser Ordner ist leer',
        gettingRedirected:'Sie werden weitergeleitet.',
        Unknown:'Unbekannt',
        Contract:'Vertrag',
        Incidents: 'Vorfälle',
        open: 'Offen',
        recentlyClosed: 'Kürzlich geschlossen',
        Installation:'Installation',
        Added:'Hinzugefügt',
        AppTitleDevices:'IoT-Dashboard | Aufzughelden by Digital Spine',
        AppTitleDevicesWithInstallatiion:'Installation ###name### | Aufzughelden by Digital Spine',
        AppTitleDocuments:'Dokumente | Aufzughelden by Digital Spine',
        AppTitleLearn:'Lernen | Aufzughelden by Digital Spine',
        AppTitleIncidents:'Vorfälle | Aufzughelden by Digital Spine',
        AppTitlePortal:'Aufzughelden by Digital Spine',
        AppTitleContracts:'Verträge | Aufzughelden by Digital Spine',
        fabricationNumber:'Fabrikationsnummer',
        SubCategory:'Unterkategorie',
        UnknownContractType : 'Unbekannter Vertragstyp',
        'ContractTypeCooperation' : 'Kooperation',
        'ContractTypeCustomer - IoT only' : 'IoT',
        'ContractTypeCustomer - Maintenance' : 'Wartung',
        'ContractTypeCustomer - Software/Consulting/Service' : 'Software/Konsulting/Service',
        'ContractTypeServicepartner' : 'Servicepartner',
        'ContractStatus_Draft':'Entwurf',
        'ContractStatus_Activated':'Aktiviert',
        'ContractStatus_In Approval Process':'Unterzeichnung',
        Startdate:'Startdatum',
        Enddate:'Enddatum',
        Costs:'Betrag',
        Active:'Status',
        Number:'Nummer',
        Type:'Typ',
        TerminationDate:'Kündigungsdatum',
        ContractNumber:'Vertragsnummer',
        AutoRenewal:'Autom. Verläng.',
        Yes:'Ja',
        No:'Nein',
        BillingCycle:'Abrechung',
        BillingAddress:'Rechnungsadresse',
        AccountName:'Account',
        CancellationPeriod:'Kündigungsfrist',
        'BillingCycle_Quarterly':'Quartalsweise',
        'BillingCycle_Yearly':'Jährlich',
        'BillingCycle_Monthly':'Monatlich',
        'BillingCycle_One-time':'Einmalig',
        'CancellationPeriod_--None--':'Keine',
        'CancellationPeriod_1 month':'1 Monat',
        'CancellationPeriod_3 months':'3 Monate',
        'CancellationPeriod_6 months':'6 Monate',
        'CancellationPeriod_12 months':'12 Monate',
        MaintenanceInterval:'Wartungsintervall',
        LastDateOfMaintenance:'Letzte Wartung',
        NextDateOfMaintenance:'Nächste Wartung',
        NextDateOfMainAudit:'Nächste Prüfung',
        NextDateOfInterimAudit:'Nächste zw. Prüfung',
        AuditInterval:'Prüfungsintervall',
        LastDateOfMainAudit:'Letzte Prüfung',
        LastDateOfInterimAudit:'Letzte zw. Prüfung',
        Maintanance:'Wartung',
        Maintenance:'Wartung',
        Months:'Monate',
        '24 Months':'24 Monate',
        '48 Months':'48 Monate',
        Controller:'Kontroller',
        Last:'Letzte',
        Next:'Nächste',
        Cancel:'Abbrechen',
        Close:'Schließen',
        Message404:'Die gesuchte Ressource konnte nicht gefunden werden!',
        Message404Contract:'Vetrag wurde nicht gefunden!',
        Message404Document:'Dokument wurde nicht gefunden!',
        Message404Installation:'Installation wurde nicht gefunden!',
        Message404Incident:'Vorfall wurde nicht gefunden!',
        User: "Nutzer",
        UpdatedOn: "Aktualisiert am",
        CaseNumber: "Fallnummer",
        CaseType: "Fallart",
        SignalType: "Typ",
        CaseSubject: "Betreff des Falles",
        SignalSubject: "Betreff des Falles",
        AffectedAssets: "Betroffene Vermögenswerte",
        Subject: "Betreff",
        Description: "Beschreibung",
        Submit:'Senden',
        TableOfContents:'Inhaltsverzeichnis',
        NewsAndUpdates:'News und Updates',
        InsightTypes_Information: 'Information',
        InsightTypes_Anomaly:'Anomalie',
        InsightTypes_Insight:'Einblick',
        InsightTypes_Performance_Insight:'Leistungseinblick',
        InsightTypes_Maintenance_Alert:'Wartungsalarm',
        InsightTypes_Safety_Alert:'Sicherheitsalarm',
        InsightTypes_Connectivity_Status:'Konnektivitätsstatus',
        InsightTypes_Environmental_Conditions:'Umweltbedingungen',
        InsightTypes_Usage_Patterns:'Nutzungsmuster',
        InsightTypes_Energy_Efficiency:'Energieeffizienz',
        InsightTypes_Message_Information: 'Information über das Verhalten des Gerätes.',
        InsightTypes_Message_Anomaly:'Hinweise zu anomalem Verhalten des Gerätes.',
        InsightTypes_Message_Insight:'Zusätzliche Einblicke in das Verhalten des Gerätes.',
        InsightTypes_Message_Performance_Insight:'Zeigt die Leistungsmetriken des Geräts an, z. B. Geschwindigkeit, Effizienz oder Ressourcennutzung.',
        InsightTypes_Message_Maintenance_Alert:'Signalisiert bevorstehenden Wartungsbedarf oder geplante Serviceanforderungen.',
        InsightTypes_Message_Safety_Alert:'Hebt sicherheitsrelevante Probleme oder potenzielle Verstöße hervor.',
        InsightTypes_Message_Connectivity_Status:'Zeigt den aktuellen Konnektivitätsstatus an, z. B. offline, online, intermittierende Konnektivität usw.',
        InsightTypes_Message_Environmental_Conditions:'Berichte über äußere Bedingungen, die das Gerät beeinflussen, wie z. B. Temperatur, Luftfeuchtigkeit oder Luftqualität.',
        InsightTypes_Message_Usage_Patterns:'Fasst Nutzungsstatistiken, häufige Aktivitäten oder ungewöhnliche Nutzungsmuster zusammen.',
        InsightTypes_Message_Energy_Efficiency:'Überwacht den Energieverbrauch und zeigt Möglichkeiten für Effizienzverbesserungen auf.',
        Edit:'Bearbeiten',
    },
};