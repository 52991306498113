import { I18n } from '@aws-amplify/core';
import React, { useEffect, useRef, useState } from 'react'
import IncidentsTable from './IncidentsTable';
import { useGlobalState } from '../../utils/globalStateProvider';
import { UserHasPermissionsFor, langChange, langInit, useContainerDimensions, IsDsMember, getAuth0Token, IsStorybook, IsStorybookAndDsMember, IsDsMemberLocal } from '../../utils/helpers';
import { strings as stringLocal} from './../utils/globalLocalization';
import { Button } from '../../components/atoms/Button';
import PopupWrapper from '../../components/PopupWrapper';
import ModalSelectInstallation from './ModalSelectInstallation';
import { useAuth0 } from '@auth0/auth0-react';
import { iNewsOrUpdataElement } from '../../learn/interfaces/NewsOrUpdate.ts';
import NewsUpdateItem from '../../learn/components/NewsUpdateItem.tsx';
import { fetchNewsAndUpdates } from '../../learn/utils/helpersFetch.tsx';
import AppTiles from './AppTiles.tsx';

const MainWrapper = () => {

  const { state } = useGlobalState()
  const [showOpenCaseDialog, setShowOpenCaseDialog] = useState<boolean>(false)
  const [userHasPermissionIncidents, setUserHasPermissionIncidents] = React.useState<boolean>(false)
  const [userIsDsMember, setUserIsDsMember] = React.useState<boolean >(false)
  const { getAccessTokenSilently } = useAuth0()
  const [ latestNews, setLatestNews ] = useState<iNewsOrUpdataElement[]>([])
  const componentRef = useRef<HTMLHeadingElement>(null)
  const { width, height } = useContainerDimensions(componentRef)
  const [buttonSize, setButtonSize] = useState<'medium'|'small'>('small')
  const [isLoadingNewsAndUpdates, setIsLoadingNewsAndUpdates] = React.useState<boolean>(true)
  const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(true)

  useEffect(() => {
    if(width < 350){
      setButtonSize('small')
    }else{
      setButtonSize('medium')
    }
  }, [width]);

  useEffect(() => {
    const fetchData = async () => {
      await getPermissions()
      await loadNewsAndUpdates()
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (state.language) { langChange({ state: state }) } else { langInit({ stringLocal }) }
  }, [state.language]);

  async function getPermissions() {
    try {
      let accessToken = await getAuth0Token(getAccessTokenSilently)
      UserHasPermissionsFor('Incidents',accessToken)?setUserHasPermissionIncidents(true):setUserHasPermissionIncidents(false)
      IsDsMemberLocal(accessToken)?setUserIsDsMember(true):setUserIsDsMember(false)

      setIsLoadingPermissions(false)
    } catch (error) {
      console.error('Error fetching permissions:', error.message)
    } finally {
      setIsLoadingPermissions(false)
    }
  }

  async function loadNewsAndUpdates() {
    try {
      setIsLoadingNewsAndUpdates(true);
      const accessToken = await getAuth0Token(getAccessTokenSilently)
      
      let pagedResult = await fetchNewsAndUpdates(0, 5, accessToken)
      setLatestNews(pagedResult.result)

      setIsLoadingNewsAndUpdates(false);
    } catch (error) {
      console.error('Error fetching News and Updates:', error.message);
      // Handle the error here if needed
    } finally {
      setIsLoadingNewsAndUpdates(false);
    }
  }

  return (
    <>
    <div className="flex">
      <div className=" w-full l:w-8/12">
        {isLoadingPermissions ? <></> : ((userHasPermissionIncidents && !userIsDsMember) ? <IncidentsTable /> : <AppTiles />)}
      </div>
      <div className="hidden l:block w-4/12 pl-10 " ref={componentRef}>

        {userHasPermissionIncidents && <blockquote>
          <span data-testid="test_btn_report_a_problem" className="block pt-4 pb-4 pl-5 pr-5 xl:pt-8 xl:pb-8 xl:pl-11 xl:pr-11 bg-blueBaby text-sm m:text-base xl:text-[22px] font-normal mt-[41px] xl:mt-[41px] xxl:mt-[48px]">
              { I18n.get('Hint_Report_a_problem') }

              <div className="w-full mb-0 mt-5 cp_report_a_problem "><Button
                disabled={false}
                theme='light'
                size={buttonSize}
                ownStyle=' w-full justify-center '
                onClick={()=>{ setShowOpenCaseDialog(true)}}
                label={ I18n.get('Btn_Report_a_problem') } />
              </div>
          </span>
        </blockquote>}
        <div className="pl-5 pr-5 xl:pl-11 xl:pr-11">
         <h1 className={(userHasPermissionIncidents) ? "mb-10 mt-16" : "mb-5 mt-10"}>{I18n.get('NewsAndUpdates')}</h1>
          {latestNews?.map((element,index) => { return <NewsUpdateItem newsUpdateITem={element} key={index}  /> })}
        </div>
      </div>
      {showOpenCaseDialog && <PopupWrapper mobileTitle={I18n.get('Report_a_problem_mobile')} closerFunction={()=>setShowOpenCaseDialog(false)} children={<ModalSelectInstallation closerFunction={()=>setShowOpenCaseDialog(false)}  />} /> }
    </div>
    <div className=" w-full l:hidden mt-20 ">
    {userHasPermissionIncidents &&
      <blockquote>
          <span data-testid="test_btn_report_a_problem" className="block pt-4 pb-4 pl-5 pr-5 xl:pt-8 xl:pb-8 xl:pl-11 xl:pr-11 bg-blueBaby text-sm m:text-base xl:text-[22px] font-normal">
              { I18n.get('Hint_Report_a_problem') }

              <div className="w-full mb-0 mt-5 cp_report_a_problem "><Button
                disabled={false}
                theme='light'
                size={buttonSize}
                ownStyle=' w-full justify-center '
                onClick={()=>{ setShowOpenCaseDialog(true)}}
                label={ I18n.get('Btn_Report_a_problem') } />
              </div>
          </span>
        </blockquote>
        }
        <div className="pl-5 pr-5  xl:pl-11 xl:pr-11">
          <h1 className="mb-5 mt-10">{I18n.get('NewsAndUpdates')}</h1>
          {latestNews?.map((element,index) => { return <NewsUpdateItem newsUpdateITem={element} key={index}  /> })}
        </div>
      </div>
    </>
  )
}

export default MainWrapper;