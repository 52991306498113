export const strings = {
    en: {
        HeadlineContracts: 'Contracts',
        HeadlineDevices: 'Assets',
        HeadlineMessages: 'Messages',
        Operational: 'Operational',
        AttentionNeeded: 'Attention needed',
        IssueDetected: 'Issue detected',
        ContractActive:'Active',
        ContractExpired:'Expired',
        ContractSignNeeded:'Signature required',
        Today:'Today',
        LastWeek:'Last week',
      },
      de: {
        HeadlineContracts: 'Verträge',
        HeadlineDevices: 'Anlagen',
        HeadlineMessages: 'Mitteilungen',
        Operational: 'Funktionsfähig',
        AttentionNeeded: 'Aufmerksamkeit benötigt',
        IssueDetected: 'Problem erkannt',
        ContractActive:'Aktiv',
        ContractExpired:'Ausgelaufen',
        ContractSignNeeded:'Unterschrift nötig',
        Today:'Heute',
        LastWeek:'Letzte Woche',
      },
};