import { DateRange } from '../interfaces/DateRange';
import { DeviceInfoResponse } from '../interfaces/DeviceInfo';
import { DoorBlockagesResponse } from '../interfaces/DoorBlockages';
import { DoorCyclesOverTimeResponse } from '../interfaces/DoorCyclesOverTime';
import { Installation, InstalltionsCoordsResponse, InstalltionsResponse } from '../interfaces/Installation';
import { RunBetweenFloorsResponse } from '../interfaces/RunBetweenFloors';
import { RunsOverTimeResponse } from '../interfaces/RunsOverTime';
import { RunsPerFloorResponse } from '../interfaces/RunsPerFloor';
import { API_URL } from './consts';
import { Contract } from 'src/documents/interfaces/Contract';
import { OrganizationResponse } from 'src/interfaces/Organization';
import { ApiError } from '../../src/documents/utils/api-error';
import { NavigateFunction } from 'react-router-dom';
import { PagedInsights } from 'src/interfaces/Insights';
import { EnergyConsumptionResponse } from 'src/interfaces/EnergyConsumption';
import { isSalesforceView } from './../documents/utils/helpers';
import { DeviceFilterEnum } from './../interfaces/DeviceFilters';

export const loadPastEvents = async (deviceId:string, from:string, to:string, setterLoading: React.Dispatch<React.SetStateAction<any | null>> ,setterResponse: React.Dispatch<React.SetStateAction<any | null>>,accessToken:string) => {
  setterLoading(true)

  try{
    const response = await customFetchWithAuthHeader(`${API_URL}/api/v1/iot/devices/${deviceId}/activity/activities`, {
      method: 'GET',
    },accessToken);

    if(response && response.status === 200){
      const data = await response.json();

      setterLoading(false)
      setterResponse(data)
    }
    else{
      setterLoading(false)
    }
  }
  catch(error:any){
    console.log(error)
    setterLoading(false)
  }
}

export const fetchAllDeviceData = async (deviceId: string,accessToken:string, dateRange?: DateRange) => {
  return Promise.all([
    fetchDeviceInfo(deviceId,accessToken),    
  ]);
};


const fetchDeviceInfo = async (deviceId: string,accessToken:string): Promise<DeviceInfoResponse> => {

  try{
    const response = await customFetchWithAuthHeader(`${API_URL}/api/v1/iot/devices/${deviceId}`, {
      method: 'GET',
    },accessToken);

    if(response.status === 200){
      const data = await response.json();
  
      if(data?.device?.mapping){
        data.device.mappingWithOrgKeys = data.device.mapping
        data.device.mapping = getCorrectMapping(data.device.mapping)
      }
      return data;
    }
    else{
      return { statusCode:response.status,message:response.statusText ,device:null}
    }
  }
  catch(error:any){
    console.log(error)
    return { statusCode:500,message:error.toString(),device:null}
  }
};

export const fetchEnergyConsumption = async (
    deviceId: string,
    queryString: string,
    accessToken:string,
    controllerRef?: React.MutableRefObject<AbortController | null | undefined>
  ): Promise<EnergyConsumptionResponse> => {
  
  try{
    const url = `${API_URL}/api/v1/iot/devices/${deviceId}/activity/energy-consumption${queryString}&eventNames=energy_meter`;
    const response = await customFetchWithAuthHeader(
        url,
        { method: 'GET' },
        accessToken,
        controllerRef
      );

    if(response.status === 200){
      const data = await response.json();
      /*
      data.data.forEach((item:EnergyConsumptionTemp)   => {
        item.power_consumption = item.power_consumption / 1000
      })
      */
      return data;
    }
    else{
      return { statusCode:response.status,message:response.statusText ,data:[]}
    }
  }
  catch(error:any){
    console.log(error)
    return { statusCode:500,message:error.toString(),data:[]}
  }
};

export function setOrReportError(setter: React.Dispatch<React.SetStateAction<any | null>>, response: any) {
  if (response.statusCode != 200 && response.message) {
    //message.error(response.message);
  } else {
    setter(response);
  }
}

export const fetchDoorCyclesOverTimeOldImplementation = async (
  deviceId: string,
  queryString: string,
  accessToken:string,
  controllerRef?: React.MutableRefObject<AbortController | null | undefined>
): Promise<DoorCyclesOverTimeResponse> => {

try{
  const url = `${API_URL}/api/v1/iot/devices/${deviceId}/activity/door-cycle-count-over-time${queryString}`;
  const response = await customFetchWithAuthHeader(
    url,
    { method: 'GET' },
    accessToken,
    controllerRef
  );

  if(response.status === 200){
    const data = await response.json();
    return data;
  }
  else{
    return []
  }
}
catch(error:any){
  console.log(error)
  return {errorMsg:error.toString()}
}
};

export const fetchDoorCyclesOverTime = async (
    deviceId: string,
    queryString: string,
    accessToken:string,
    controllerRef?: React.MutableRefObject<AbortController | null | undefined>
  ): Promise<DoorCyclesOverTimeResponse> => {

  try{
    const url = `${API_URL}/api/v1/iot/devices/${deviceId}/activity/door-cycle-count-over-time${queryString}`;
    const response = await customFetchWithAuthHeader(
      url,
      { method: 'GET' },
      accessToken,
      controllerRef
    );

    if(response.status === 200){
      const data = await response.json();
      return data;
    }
    else{
      return []
    }
  }
  catch(error:any){
    console.log(error)
    return {errorMsg:error.toString()}
  }
};

export const fetchRunsBetweenAllFloors = async (
    deviceId: string,
    queryString: string,
    accessToken:string,
    controllerRef?: React.MutableRefObject<AbortController | null | undefined>
  ): Promise<RunBetweenFloorsResponse> => {

  try{
    const url = `${API_URL}/api/v1/iot/devices/${deviceId}/activity/runs-between-all-floors${queryString}`;
    const response = await customFetchWithAuthHeader(
      url,
      {method: 'GET'},
      accessToken,
      controllerRef);

    if(response.status === 200){
      const data = await response.json();
      return data;
    }
    else{
      return []
    }
  }
  catch(error:any){
    console.log(error)
    return { errorMsg:error.toString()}
  }
};

export const fetchDoorBlocked = async (
    deviceId: string,
    queryString: string,
    accessToken:string,
    controllerRef?: React.MutableRefObject<AbortController | null | undefined>
  ): Promise<DoorBlockagesResponse> => {

  try{
    const url = `${API_URL}/api/v1/iot/devices/${deviceId}/activity/door-blocked${queryString}`;
    const response = await customFetchWithAuthHeader(
      url,
      { method: 'GET'},
      accessToken,
      controllerRef);

    if(response.status === 200){
      const data = await response.json();
      return data;
    }
    else{
      return []
    }
  }
  catch(error:any){
    console.log(error)
    return { errorMsg:error.toString()}
  }
};

export const fetchRunsOverTime = async (
  deviceId: string,
  queryString: string,
  accessToken:string,
  controllerRef?: React.MutableRefObject<AbortController | null | undefined>
): Promise<RunsOverTimeResponse> => {

  try{
    const url = `${API_URL}/api/v1/iot/devices/${deviceId}/activity/runs-over-time${queryString}`;

    const response = await customFetchWithAuthHeader(
      url,
      {method: 'GET'},
      accessToken,
      controllerRef);

    if(response.status === 200){
      return await response.json()
    }
    else{
      return []
    }
  }
  catch(error:any){
    console.log(error)
    return { errorMsg:error.toString()}
  }
};

const getCorrectMapping = (mapping:any) => {
  // mapping appears sometimes 
  // the customer care team sometimes with a leading 0
  // {0: -3, 1: -2, 2: -1, 3: 'G', 4: 1, 5: 2}
  // but sometimes with a leading 1
  // {1: -3, 2: -2, 3: -1, 4: 'G', 5: 1, 6: 2}
  // so we unify it here to start everytime with 0

  // but first sort the object by the keys...  there arent sorted and possible key values === -1  \o/

  var unsortedArrayOfMapping:{key:string,value:string}[]=[]

  for (const key in mapping) {
    if (mapping.hasOwnProperty(key)) {
      unsortedArrayOfMapping.push({key:key,value:mapping[key]})
    }
  }

  var sortedArrayOfMapping = unsortedArrayOfMapping.sort((a, b) => parseInt(a.key) - parseInt(b.key));

  let newMapping:{ [key: string]: string }  = {}
  let keyPosition = 0
  sortedArrayOfMapping.forEach(elem => {
    newMapping[keyPosition.toString()] = elem.value
    keyPosition++
  })

  return newMapping
}

export const fetchDevicesStatus = async (deviceLis:string[],accessToken:string): Promise<DeviceInfoResponse[]> => {
  try{
    const response = await customFetchWithAuthHeader(`${API_URL}/api/v1/iot/devices?deviceIds=`+encodeURIComponent(deviceLis.join(',')), {
      method: 'GET',
    },accessToken);
    const data = await response.json();
  
    if(data[0]?.device.mapping){
      data[0].device.mappingWithOrgKeys = data[0].device.mapping
      data[0].device.mapping = getCorrectMapping(data[0].device.mapping)
    }
  
    return data;  
  }
  catch(error){
    console.log(error)
    return []
  }
};

export const fetchInstallation = async (id:string,accessToken:string ): Promise<Response> => {

  const response = await customFetchWithAuthHeader(API_URL + '/api/v1/assets/'+id, {
    method: 'GET',
  },accessToken);

  return response;
};

export const fetchUserIntallations = async (
  page: number,
  pageSize: number,
  searchText: string,
  sortBy: string,
  filterBy: string,
  accessToken: string,
  controllerRef?: React.MutableRefObject<AbortController | null | undefined>): Promise<InstalltionsResponse> => {

  let allInstallations = undefined
  let sortByParam = (!sortBy || sortBy === '')? '' : '&sortBy='+encodeURIComponent(sortBy)
  let filterByParam = (!filterBy || filterBy === '' || filterBy === DeviceFilterEnum.All)? '' : '&filterBy='+encodeURIComponent(filterBy)
  const response = await customFetchWithAuthHeader(API_URL + '/api/v1/assets?validIotOnly=false&take='+pageSize.toString()+'&skip='+((page-1)*pageSize).toString()+'&text='+encodeURIComponent(searchText)+sortByParam+filterByParam, {
    method: 'GET',
  },accessToken, controllerRef);
  allInstallations = await response.json()
  
  return allInstallations;
};

export const fetchUserIntallationsCoords = async (
  page: number,
  pageSize: number,
  searchText: string,
  sortBy: string,
  accessToken: string,
  controllerRef: React.MutableRefObject<AbortController | null | undefined>): Promise<InstalltionsCoordsResponse> => {

  let allInstallations = undefined
  let sortByParam = (!sortBy || sortBy === '')? '' : '&sortBy='+encodeURIComponent(sortBy)
  const response = await customFetchWithAuthHeader(API_URL + '/api/v1/assets?validIotOnly=false&coords=true&text='+encodeURIComponent(searchText)+sortByParam, {
    method: 'GET',
  },accessToken,controllerRef);
  allInstallations = await response.json()
  
  return allInstallations;
};

export const fetchOrganizationName = async (organizationIdentifier:string): Promise<OrganizationResponse|null> => {

  const headers = {
    'Content-Type': 'application/json',
    'organizationId':organizationIdentifier
  };
  const response = await fetch(API_URL + '/api/v1/discoveryservice', {
    headers,
  });

  let organizationResponse = await response.json()
  let organization:OrganizationResponse|null = null

  if(organizationResponse && organizationResponse.name){
    organization = organizationResponse
  }

  return organization
};

export const fetchOrganizationIdentifier = async (organizationName:string,retries:number=1): Promise<string|null> => {
  try{
    const headers = {
      'Content-Type': 'application/json',
      'organizationName':organizationName
    };
    const response = await fetch(API_URL + '/api/v1/discoveryservice', {
      headers,
    });
  
    if(!response.ok && retries < 5){
      return await fetchOrganizationIdentifier(organizationName,retries+1)
    }

    let organizationResponse = await response.json()
    let organizationIdentifier = null
  
    if(organizationResponse && organizationResponse.auth0Id){
      organizationIdentifier = organizationResponse.auth0Id
    }
  
    return organizationIdentifier
  }
  catch{
    if(retries < 5){
      return await fetchOrganizationIdentifier(organizationName,retries+1)
    }
    else{
      return null
    }
  }
};

export const getContractById = async (contractId: string ,accessToken:string): Promise<Contract|null> => {
  const url = `${API_URL}/api/v1/contracts/${contractId}?includeAssets=true`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  },accessToken);
  const data = await response.json();
  if(data.contract){
    return data.contract;
  }
  else{
    return null
  }
};

export const customFetchWithAuthHeader = async (
    url: string,
    options: RequestInit,
    accessToken:string,
    controllerRef?: React.MutableRefObject<AbortController | null | undefined>
  ) => {

  let addApplication = {}

  if (isSalesforceView() ) {
    addApplication = { 'x-application': 'salesforce' }
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    ...addApplication
  };

  try{

    if (controllerRef) {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      const controller = new AbortController();
      controllerRef.current = controller;
      options.signal = controllerRef.current?.signal
    }

    const response = await fetch(url, {
      ...options,
      headers,
    });
    return response;
  }
  catch(error){
    throw error
  }
};

export const isValidDoorConfiguration = (doorConfiguration:any[]) => {
  let acceptableDoorOrientations = ['North','South','West','East']
  let listOfPositionFound:string[] = []
  let configurationIsValid = true
  
  doorConfiguration.forEach(item => {
    if(listOfPositionFound.includes(item)){
      // found the same direction
      configurationIsValid = false
    }
    else{
      if(acceptableDoorOrientations.includes(item)){
        listOfPositionFound.push(item)
      }
      else{
        // String not like it should be
        configurationIsValid = false
      }
    }
  })
  return configurationIsValid
}

export const patchAssetTitle = async (title:string, assetID:string,accessToken:string): Promise<Installation> => {

  let allInstallations = undefined
  let params = {
    customLabel:title
  }
  const response = await customFetchWithAuthHeader(API_URL + '/api/v1/assets/'+assetID+'/', {
    method: 'PATCH',
    body: JSON.stringify(params) 
  },accessToken);
  allInstallations = await response.json()
  
  return allInstallations;
};

export function handleApiError(error:any,navigate:NavigateFunction) {
  if (error instanceof ApiError) {
    if (error.statusCode === 403) {
      navigate('/404')
    } else if (error.statusCode === 404) {
      navigate('/404')
    }
  } else {
    // Handle general errors
    console.error('An error occurred:', error);
  }
}

export const fetchCases = async (page:number, pageSize:number, searchText:string, sortBy:string,deviceName:string,accessToken:string): Promise<PagedInsights> => {

  let allInstallations = undefined
  let sortByParam = (!sortBy || sortBy === '')? '' : '&sortBy='+encodeURIComponent(sortBy)

  //deviceName = 'L1Z02KC8OSL47'

  const response = await customFetchWithAuthHeader(API_URL + `/api/v1/device-insights/devices/${deviceName}/?take=`+pageSize.toString()+'&skip='+((page-1)*pageSize).toString()+sortByParam, {
    method: 'GET',
  },accessToken);
  allInstallations = await response.json()
  
  return allInstallations;
};