import React, { ReactNode } from 'react'
import {  } from 'react-router-dom'
import { useEffect } from 'react'
import './TableMain.css';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

interface TableCell {class:string,text:ReactNode,textPlain?:string}

export interface TableProps {
  th: ({ellipsis?:boolean,twoLinesEllipses?:boolean,onCellClick?:Function} & TableCell)[]
  tr: {
    cells:TableCell[],
    trOnClick?: Function
  }[]
  noDataLabel: string
  thead?: "sticky-documents-detail" | "select-installation"
  height: "max-h-[50vh]" | "unset";
}

const TableMain = (props: {  tableContent:TableProps|null, className?:string }) => {
      
  const [tableContent,setTableContent] = React.useState<TableProps | null>(null)
  const [dsTable,setDsTable] = React.useState<JSX.Element | null>(null)
  const thTdFixStyle = ' font-medium bg-blueDark text-white items-end pl-4 l:pb-4 '
  const thTdFixStyleTwoLines = ' font-medium bg-blueDark text-white items-end pl-0 l:pb-4 '
  const trTdFixStyle = ' flex items-center overflow-hidden p-4 h-16 m:h-24 '
  
  useEffect(() => {
    setTableContent(props.tableContent)
  }, [props.tableContent]);

  useEffect(() => {
    BuildTable()
  }, [tableContent]);

  const BuildTable = () => {
    
    if(!tableContent || tableContent.tr.length === 0 ){
      setDsTable(<div className="flex " style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight ">{tableContent?.noDataLabel}</div>
        </div>)
    }
    else{

      setDsTable(<><table className={" table-main text-sm m:text-lbase l:text-l xxl:text-lg select-none"} align="left" >
      <thead className={props.tableContent?.thead}>
        <tr className=" h-14 m:h-24 align-bottom flex w-full bg-blueDark ">
          {tableContent.th.map((thElement,index)=>{
            if(thElement.ellipsis){
              return <th onClick={()=>{ thElement.onCellClick?thElement.onCellClick():false}} className={ thTdFixStyle + thElement.class + ' ml-5 mr-5  pl-0 '} key={index}><span className="flex items-center ">{thElement.text}</span></th>
            }
            else if(thElement.twoLinesEllipses){
              return <th onClick={()=>{ thElement.onCellClick?thElement.onCellClick():false}} className={ thTdFixStyleTwoLines + thElement.class + ' ml-5 mr-5 '} key={index}><span className="flex items-center ">{thElement.text}</span></th>
            }
            else{
              return <th onClick={()=>{ thElement.onCellClick?thElement.onCellClick():false}} className={ thTdFixStyle + thElement.class } key={index}><span className="flex items-center">{thElement.text}</span></th>
            }
          })}
        </tr>
      </thead>
      <tbody className={tableContent.height + " " + "bg-grey-light flex flex-col items-center justify-between  w-full "} >
        {tableContent.tr.map((trElement,index)=>{
          let rowClassName= index % 2 === 0 ? '  hover:bg-blueBaby cursor-pointer flex w-full' : ' bg-greyLighter  hover:bg-blueBaby cursor-pointer flex w-full'    
          
          return <tr className={rowClassName} key={index} onClick={()=>{(trElement.trOnClick? trElement.trOnClick(): false)}} >{trElement.cells.map((tdElement,index)=>{
            if( tableContent.th[index].ellipsis){
              return <td className={tdElement.class + trTdFixStyle + ' ellipsis ml-5 mr-5 '} key={index}><span title={tdElement.textPlain?tdElement.textPlain:''}>{tdElement.text}</span></td>
            }
            else if(tableContent.th[index].twoLinesEllipses){
              return <td className={tdElement.class + trTdFixStyle + ' twoLinesEllipses pl-0 pr-0 ml-5 mr-5 '} key={index}><span title={tdElement.textPlain?tdElement.textPlain:''}>{tdElement.text}</span></td>
            }
            else{
              return <td className={tdElement.class + trTdFixStyle} key={index}>{tdElement.text}</td>
            }
          })}
          </tr>
        })}
      </tbody>
    </table>
    </>
    )
    }
  }

  return (
    <div className={(props.className?props.className:'') + " flex flex-col"}>
      {dsTable}
    </div>
  );
};

export default TableMain;
